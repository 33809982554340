.slick-dots-custom {
  /* position: absolute; */
  /* bottom: -30px; */
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots-custom li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  align-content: center;
}
